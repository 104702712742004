import Layout from "../../../../lib/Layout";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { PermissionContext } from "../../../../lib/PermissionContext";
import { LanguageContext } from "../../../../lib/LanguageContext";
import { ThreeCircles } from "react-loader-spinner";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import { PORT } from "../../../../Api/api";
import "./style.css";
import { formatDate } from "../../../../lib/FormattedDate";
import { FaArrowRight, FaEdit } from "react-icons/fa";
import { FaCheckCircle, FaDollarSign } from "react-icons/fa";
import Tooltip from "@mui/material/Tooltip";
import { MdOutlineCreditScore } from "react-icons/md";
import { MdOutlineCreditCardOff } from "react-icons/md";
import Swal from "sweetalert2";
import { FaLevelDownAlt } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";
import { FaTimesCircle } from "react-icons/fa";
import "./style.css";
import Loading from "../../../../Loading/Loading";
import { useNavigate } from "react-router-dom";
import { CiEdit } from "react-icons/ci";
import ViewAllAffiliateBills from "../../../../GetData/ViewAllAffiliateBills";
const ViewAffiliateBilling = () => {
  const { language } = useContext(LanguageContext);
  const [affiliateData, setAffiliateData] = useState([]);
  const { permissions } = useContext(PermissionContext);
  const [societyAgencyFilterData, setAgencyFilterData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [societyLoading, setSocietyLoading] = useState(false);
  const [selectedAgencyId, setSelectedAgencyId] = useState("");
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [societyData, setSocietyData] = useState([]);
  const [editCommission, setEditCommission] = useState(false);
  const [searchingSocityData, setSearchingSocityData] = useState(false);
  const navigate = useNavigate();
  const [commissionValue, setCommissionValue] = useState("");
  const [viewMode, setViewMode] = useState("select");
  const getSelectedId = JSON.parse(localStorage.getItem("selectedId"));
  const toggleViewMode = () => {
    setViewMode(viewMode === "select" ? "all" : "select");
  };
  //fetch affiliate agency
  const fetchaffiliateAgency = async () => {
    try {
      const response = await axios.get(`${PORT}/getAffiliateUser`);
      const res = (await response.data) || [];
      const filterApprovedAgency = await res.filter(
        (item) => item.status === "Approved"
      );
      setAffiliateData(filterApprovedAgency);
      if (getSelectedId && editCommission == false) {
        setSelectedAgency(getSelectedId);
        setSelectedAgencyId(getSelectedId._id);
        fetchSociety(getSelectedId._id);
        setAgencyFilterData(getSelectedId);
        setViewMode("select");
      }
      else {
        //15/01/2025 minor changes
        const firstSociety = filterApprovedAgency?.[0] || [];
        setSelectedAgency(firstSociety);
        fetchSociety(firstSociety?._id);
        setSelectedAgencyId(firstSociety?._id);
        setAgencyFilterData(firstSociety);
      }

      setTimeout(() => {
        setLoading(false);
      }, 1500);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching affiliate users:", error);
    }
  };
  //fetch society
  const fetchSociety = async (id) => {
    try {
      setSocietyLoading(true);
      const response = await axios.get(`${PORT}/getSocietyData`);
      const res = await response.data.societyData;

      const filterAffiliateSociety = await res.filter(
        (item) => item.affiliateId === (id || selectedAgencyId)
      );
      setSocietyData(filterAffiliateSociety);
      setSearchingSocityData(filterAffiliateSociety);
      setSocietyLoading(false);
    } catch (error) {
      console.log("Error fetching society data:", error);
    }
  };
  // Function to handle search input change
  const handleSearchChange = (e) => {
    const searchText = e.target.value.toLowerCase();
    if (searchText === "") {
      // If the search text is empty, display all societies
      setSocietyData(searchingSocityData);
    } else {
      // Filter society data based on the search text
      const filteredSocieties = searchingSocityData.filter((society) =>
        society.name.toLowerCase().includes(searchText)
      );

      // Update the state with filtered data
      setSocietyData(filteredSocieties);
    }
  };

  //custom Function to convert the Date in month
  function formatDateToCustom(dateString) {
    const date = new Date(dateString);
    // Map of month numbers to short names
    const monthShortNames = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    // Extract date parts
    const day = date.getDate().toString().padStart(2, "0"); // Ensures two-digit day
    const month = monthShortNames[date.getMonth()]; // Converts month to short name
    const year = date.getFullYear(); // Gets the full year

    // Combine parts in the desired format
    return `${day}-${month}-${year}`;
  }
  // Handle selection changes
  const handleSocietyChange = (event, value) => {
    if (value) {
      localStorage.setItem("selectedId", JSON.stringify(value));
      setEditCommission(false);
      setSelectedAgency(value);
      setSelectedAgencyId(value._id);
      fetchSociety(value._id);
      setAgencyFilterData(value);
      setViewMode("select");
    }
  };
  // Helper function to generate a random color
  const getRandomColor = () =>
    `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  //handleViewSocietyTransaction
  const handleViewSocietyTransaction = (id) => {
    navigate(`/admin/viewAffiliateSocietyTransactions/${id}`);
  };
  //handleCommissionEdit
  const handleCommissionEdit = (index, item) => {
    setEditCommission(true);
  };
  //handleCancel
  const handleCancel = () => {
    setEditCommission(false);
  };
  // Handle input change
  const handleInputChange = (value) => {
    if (/^\d*\.?\d*%?$/.test(value)) {
      // Allow only numbers, decimals, and an optional `%` at the end
      setCommissionValue(value);
    }
  };
  const handleSaved = (id, society) => {
    if (!commissionValue.endsWith("%")) {
      alert("Please add a % symbol to the value.");
      return;
    }
    onSave(commissionValue, id, society); // Call the provided onSave function with the numeric value
  };
  const onSave = async (numericValue, id, society) => {
    try {
      const data = {
        commissionRate: numericValue,
        affiliateId: id,
      };
      const response = await axios.post(
        `${PORT}/modifyAffiliateCommission`,
        data
      );
      setEditCommission(false);
      setSelectedAgency(response.data.updatedAffiliate);
      setSelectedAgencyId(response.data.updatedAffiliate._id);
      setAgencyFilterData(response.data.updatedAffiliate);
      localStorage.setItem("selectedId", JSON.stringify(response.data.updatedAffiliate));
      // fetchSociety()
      setCommissionValue("");
    } catch (error) {
      console.log(error);
    }
  };

  //function lock
  useEffect(() => {
    fetchaffiliateAgency();
  }, []);
  return (
    <div>
      <Layout>
        <div class="container-fluid py-4 ">
          <div class="row">
            <div class="col-12 ">
              <div class="card mb-4">
                <div class="card-header pb-0">
                  <div className="card-body px-0 pt-0 pb-2 w-100 ">
                    {loading ? (
                      <Loading />
                    ) : permissions[15]?.actions.read ? (
                      <div className="table-responsive p-0 bg-black">
                        <div className="top-heading-billing-dropdown-allFilter">
                          {/* inputSelected Society */}
                          <div>
                            <Autocomplete
                              id="country-select-demo"
                              sx={{
                                width: 250,
                                margin: "10px",
                                "&.Mui-disabled": {
                                  backgroundColor: "#f5f5f5",
                                  cursor: "not-allowed",
                                  opacity: 0.6,
                                },
                              }}
                              options={affiliateData}
                              value={selectedAgency}
                              autoHighlight
                              getOptionLabel={(option) =>
                                option?.agencyName ? option.agencyName : ""
                              }
                              onChange={handleSocietyChange}
                              renderOption={(props, option) => {
                                const { key, ...optionProps } = props;
                                // Safely handle option.code with a fallback to 'default'
                                const flagCode = option.code
                                  ? option.code.toLowerCase()
                                  : "default";

                                return (
                                  <Box
                                    key={key}
                                    component="li"
                                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                    {...optionProps}
                                  >
                                    <img
                                      loading="lazy"
                                      width="20"
                                      srcSet={`https://flagcdn.com/w40/${flagCode}.png 2x`} // Use flagCode here
                                      src={`https://flagcdn.com/w20/${flagCode}.png`} // Use flagCode here
                                      alt=""
                                    />
                                    {option?.agencyName}
                                  </Box>
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={
                                    language === "hindi"
                                      ? "Choose a Agency"
                                      : "एक एजेंसी चुनें"
                                  }
                                  slotProps={{
                                    htmlInput: {
                                      ...params.inputProps,
                                      autoComplete: "new-password", // disable autocomplete and autofill
                                    },
                                  }}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <React.Fragment>
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                    ),
                                  }}
                                  InputLabelProps={{
                                    shrink: true,
                                    style: { color: "#5e72e4" }, // Label color
                                  }}
                                />
                              )}
                              disableClearable
                            />
                          </div>
                        </div>
                        <>
                          <div className="billing-container">
                            {/* Top Section */}
                            <div className="section-wrapper">
                              {/* Current Plan Summary */}
                              <div className="card-billing-wrapper">
                                <div className="card-billing-heading1">
                                  <div className="card-billing-heading-title">
                                    {language === "hindi"
                                      ? "Agency Details"
                                      : "एजेंसी विवरण"}
                                  </div>
                                  <div className="card-billing-heading-button">
                                    {/* <button>Upgrade</button> */}
                                  </div>
                                </div>
                                {affiliateData ? (
                                  <div className="card-billing-body">
                                    <div className="card-billing-body-details">
                                      <p>
                                        <h6>
                                          {language === "hindi"
                                            ? "Agency Name"
                                            : "एजेंसी का नाम"}
                                        </h6>
                                        <strong
                                          style={{
                                            // backgroundColor: bgColorPlanType,
                                            padding: "5px 10px",
                                            borderRadius: "5px",
                                            color: "#000", // Black text for contrast
                                          }}
                                        >
                                          {loading ? (
                                            <div className="">
                                              <ThreeCircles
                                                visible={true}
                                                height={30}
                                                width={30}
                                                color="#5e72e4"
                                                ariaLabel="three-circles-loading"
                                              />
                                            </div>
                                          ) : (
                                            societyAgencyFilterData?.agencyName ||
                                            ""
                                          )}
                                        </strong>
                                      </p>
                                    </div>
                                    <div className="card-billing-body-details">
                                      <p>
                                        <h6>
                                          {language === "hindi"
                                            ? "Contact Person"
                                            : "संपर्क व्यक्ति"}
                                        </h6>

                                        <strong
                                          style={{
                                            // backgroundColor: bgColorHouseCount,
                                            padding: "5px 10px",
                                            borderRadius: "5px",
                                            color: "#000", // Black text for contrast
                                          }}
                                        >
                                          {loading ? (
                                            <div className="">
                                              <ThreeCircles
                                                visible={true}
                                                height={30}
                                                width={30}
                                                color="#5e72e4"
                                                ariaLabel="three-circles-loading"
                                              />
                                            </div>
                                          ) : (
                                            societyAgencyFilterData?.contactPerson ||
                                            ""
                                          )}
                                        </strong>
                                      </p>
                                    </div>
                                    <div className="card-billing-body-details">
                                      <p>
                                        <h6>
                                          {language === "hindi"
                                            ? "Email Address"
                                            : "ईमेल पता"}
                                        </h6>

                                        <strong
                                          style={{
                                            // backgroundColor: bgColorHouseCount,
                                            padding: "5px 10px",
                                            borderRadius: "5px",
                                            color: "#000", // Black text for contrast
                                          }}
                                        >
                                          {loading ? (
                                            <div className="">
                                              <ThreeCircles
                                                visible={true}
                                                height={30}
                                                width={30}
                                                color="#5e72e4"
                                                ariaLabel="three-circles-loading"
                                              />
                                            </div>
                                          ) : (
                                            societyAgencyFilterData?.email || ""
                                          )}
                                        </strong>
                                      </p>
                                    </div>
                                    <div className="card-billing-body-details">
                                      <p>
                                        <h6>
                                          {" "}
                                          {language === "hindi"
                                            ? "Phone no."
                                            : "फ़ोन नंबर"}{" "}
                                        </h6>

                                        <strong
                                          style={{
                                            // backgroundColor: bgColorHouseCount,
                                            padding: "5px 10px",
                                            borderRadius: "5px",
                                            color: "#000", // Black text for contrast
                                          }}
                                        >
                                          {loading ? (
                                            <div className="">
                                              <ThreeCircles
                                                visible={true}
                                                height={30}
                                                width={30}
                                                color="#5e72e4"
                                                ariaLabel="three-circles-loading"
                                              />
                                            </div>
                                          ) : (
                                            societyAgencyFilterData?.phone || ""
                                          )}
                                        </strong>
                                      </p>
                                    </div>
                                    <div className="card-billing-body-details">
                                      <p>
                                        <h6>
                                          {language === "hindi"
                                            ? "Status"
                                            : "स्थिति"}
                                        </h6>

                                        <strong
                                          style={{
                                            // backgroundColor: bgColorHouseCount,
                                            padding: "5px 10px",
                                            borderRadius: "5px",
                                            color: "#000", // Black text for contrast
                                          }}
                                        >
                                          {loading ? (
                                            <div className="">
                                              <ThreeCircles
                                                visible={true}
                                                height={30}
                                                width={30}
                                                color="#5e72e4"
                                                ariaLabel="three-circles-loading"
                                              />
                                            </div>
                                          ) : (
                                            <span
                                              className={`status-badge ${societyAgencyFilterData?.status?.toLowerCase()}`}
                                            >
                                              {societyAgencyFilterData?.status ||
                                                ""}
                                            </span>
                                          )}
                                        </strong>
                                      </p>
                                    </div>
                                    <div className="card-billing-body-details">
                                      <p>
                                        <h6>
                                          {language === "hindi"
                                            ? "Address"
                                            : "पता"}
                                        </h6>

                                        <strong
                                          style={{
                                            maxWidth: "280px",
                                            maxHeight: "100px",
                                            overflow: "auto", // स्क्रॉल जोड़ने के लिए
                                            whiteSpace: "normal", // टेक्स्ट को लाइन में रखने के लिए
                                            wordWrap: "break-word", // टेक्स्ट को ब्रेक करने के लिए
                                            padding: "5px", // थोड़ा पेडिंग जोड़ें

                                            borderRadius: "5px", // थोड़ा गोल बॉर्डर
                                          }}
                                          className="affiliate-address-div"
                                        >
                                          {societyAgencyFilterData?.address ||
                                            "No Data"}
                                        </strong>
                                      </p>
                                    </div>
                                    {/* commsion rate */}
                                    <div className="card-billing-body-details">
                                      <p>
                                        <h6>
                                          {language === "hindi"
                                            ? "Commission Rate"
                                            : "कमीशन दर"}
                                        </h6>
                                      </p>
                                      <div className="commission-edit">
                                        <div>
                                          {editCommission ? (
                                            <div>
                                              <input
                                                type="text"
                                                value={commissionValue}
                                                onChange={(e) =>
                                                  handleInputChange(
                                                    e.target.value
                                                  )
                                                }
                                                className="commission-input"
                                              />
                                            </div>
                                          ) : (
                                            <strong
                                              style={{
                                                // backgroundColor: bgColorHouseCount,
                                                padding: "5px 10px",
                                                borderRadius: "5px",
                                                color: "#000", // Black text for contrast
                                              }}
                                              className=""
                                            >
                                              {societyAgencyFilterData?.commissionRate ||
                                                "20%"}
                                            </strong>
                                          )}
                                        </div>
                                        <div>
                                          {editCommission ? (
                                            <div className="commission-edit-cancel-view">
                                              <button
                                                onClick={() =>
                                                  handleSaved(
                                                    societyAgencyFilterData?._id,
                                                    societyAgencyFilterData
                                                  )
                                                }
                                              >
                                                Saved
                                              </button>
                                              <button onClick={handleCancel}>
                                                Cancel
                                              </button>
                                            </div>
                                          ) : (
                                            <div onClick={handleCommissionEdit}>
                                              <CiEdit className="affiliate-commssion-edit-button" />
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="billing-no-data"></div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="billing-container">
                            <div className="section-wrapper">
                              {/* Current Plan Summary */}
                              <div className="card-billing-wrapper">
                                <div
                                  className="card-billing-heading2"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    padding: "10px",
                                    position: "relative",
                                    zIndex: 1,
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "20px",
                                      width: "100%",
                                    }}
                                  >
                                    <div className="card-billing-heading-title">
                                      {language === "hindi"
                                        ? "Added Society"
                                        : "जोड़ी गई सोसाइटी"}
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "15px",
                                        flex: 1,
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      {viewMode === "select" && (
                                        <div
                                          style={{
                                            position: "relative",
                                            maxWidth: "250px",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            id="search-society"
                                            name="searchSociety"
                                            placeholder="Search society..."
                                            onChange={handleSearchChange}
                                            style={{
                                              padding: "12px 28px 12px 12px",
                                              border: "1px solid #dee2e6",
                                              borderRadius: "4px",
                                              fontSize: "14px",
                                              width: "100%",
                                              outline: "none",
                                            }}
                                          />
                                          <span
                                            style={{
                                              position: "absolute",
                                              right: "8px",
                                              top: "50%",
                                              transform: "translateY(-50%)",
                                              color: "#6c757d",
                                              fontSize: "14px",
                                              pointerEvents: "none",
                                            }}
                                          >
                                            🔍
                                          </span>
                                        </div>
                                      )}

                                      <button
                                        onClick={toggleViewMode}
                                        style={{
                                          padding: "6px 12px",
                                          backgroundColor: "#fff",
                                          color: "#333",
                                          border: "1px solid #dee2e6",
                                          borderRadius: "4px",
                                          cursor: "pointer",
                                          fontSize: "14px",
                                          whiteSpace: "nowrap",
                                          transition: "all 0.2s",
                                          position: "relative",
                                          zIndex: 2,
                                        }}
                                      >
                                        {viewMode === "select"
                                          ? "View All Bills "
                                          : "Select Society"}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                {viewMode === "select" ? (
                                  <>
                                    {" "}
                                    <div className="card-billing-body">
                                      <div className="affiliate-container">
                                        {societyLoading ? (
                                          <Loading />
                                        ) : societyData?.length > 0 ? (
                                          societyData.map((item) => {
                                            const borderColor =
                                              getRandomColor();
                                            return (
                                              <div
                                                key={item.id}
                                                className="society-card"
                                                style={{
                                                  borderBottom: `4px solid ${borderColor}`,
                                                }}
                                              >
                                                <div className="card-content">
                                                  <p className="card-title">
                                                    {item.name}
                                                  </p>
                                                  <div className="timeline">
                                                    <span className="timeline-date">
                                                      {formatDate(
                                                        item.submitedDate
                                                      )}
                                                    </span>

                                                    <span className="timeline-date">
                                                      {item.submitedTime}
                                                    </span>
                                                  </div>
                                                  <div className="status">
                                                    <span>Status:</span>
                                                    <span
                                                      className="status-text"
                                                      style={{
                                                        color:
                                                          item.status ===
                                                            "approved"
                                                            ? "green"
                                                            : item.status ===
                                                              "rejected"
                                                              ? "red"
                                                              : "orange",
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      {item.status.toUpperCase()}
                                                    </span>
                                                  </div>
                                                  <div className="card-footer">
                                                    {item.status ===
                                                      "approved" ? (
                                                      <button
                                                        className="action-button"
                                                        onClick={() =>
                                                          handleViewSocietyTransaction(
                                                            item._id
                                                          )
                                                        }
                                                      >
                                                        <FaArrowRight />
                                                      </button>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          })
                                        ) : (
                                          <div className="affilate-no-data">
                                            {language === "english"
                                              ? "कोई डेटा नहीं"
                                              : "No Data"}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <ViewAllAffiliateBills
                                    selectedAgencyId={selectedAgencyId}
                                    isAdmin={true}
                                  />
                                )}

                                <div className="card-billing-progress-body">
                                  <div className="card-billing-progress-details"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      </div>
                    ) : (
                      <div className="permission">
                        <h2>You do not have permission to read this data</h2>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
        {/* <ViewAllAffiliateBills/> */}
      </Layout>
    </div>
  );
};

export default ViewAffiliateBilling;
