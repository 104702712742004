import React, { useEffect, useRef, useState } from "react";
import AdminNavbar from "./AdminNavbar";
import { MdDelete } from "react-icons/md";
import "./society.css";
import AddSociety from "./AddSociety";
import axios from "axios";
import { PORT } from "../Api/api";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import { TiExport } from "react-icons/ti";
import Swal from "sweetalert2";
import Pagination from "@mui/material/Pagination";
import { useNavigate } from "react-router-dom";
import GetSocietyDetails from "./GetSocietyDetails";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Stack from "@mui/material/Stack";
import { FaCheckCircle, FaHouseUser, FaTimesCircle } from "react-icons/fa";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { ThreeCircles } from "react-loader-spinner";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import CommonNav from "../AdminSetting/CommonNav";
import Layout from "../lib/Layout";
import { PermissionContext } from "../lib/PermissionContext";
import { useContext } from "react";
import { LanguageContext } from "../lib/LanguageContext";
import { MdOutlineModeEdit } from "react-icons/md";
import { formatDate } from "../lib/FormattedDate";
import Tooltip from "@mui/material/Tooltip";
import decodeToken from "../affiliate/affiliatePanel/affiliateLib/decodeToken/DecodeToken";
import getCurrentDate from "../lib/CurrentData";
import getCurrentTime from "../lib/CurrentTime";
import generateRandomId from "../lib/generateRandomId";
import { toast, ToastContainer } from "react-toastify";
const SocietyDetails = () => {
  const { language } = useContext(LanguageContext);
  const { permissions } = useContext(PermissionContext);
  const [societyData, setSocietyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const getRoleId = JSON.parse(localStorage.getItem("roleId"));
  const [loadingPermission, setLoadingPermission] = useState(true);
  const [rejectionMessage, setRejectionMessage] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const accordionRef = useRef(null);
  const perPage = 10;
  const navigate = useNavigate();
  const handleSociety = () => {
    navigate("/admin/add-society");
  };
  const getSocietyData = async () => {
    try {
      const response = await axios.get(`${PORT}/getSocietyData`);
      const res = await response.data.societyData;
      setSocietyData(res.reverse());
      setTimeout(() => {
        setLoadingPermission(false);
      }, 1000);

      setFilteredData(res);
      setLoading(false);
      const totalItems = res.length;
      setTotalPages(Math.ceil(totalItems / perPage));
    } catch (error) {
      console.log("Error fetching society data:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        await axios.delete(`${PORT}/delSociety/${id}`);
        getSocietyData();
        Swal.fire({
          title: "Deleted!",
          text: "Your Society has been deleted.",
          icon: "success",
          timer: 1500,
          timerProgressBar: true,
        });
      }
    } catch (error) {
      console.log("Error deleting society:", error);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  const handleSearchInput = (e) => {
    const query = e.target.value.trim();
    setSearchQuery(query);
    const filteredData = societyData.filter((item) =>
      item.name.toLowerCase().includes(query)
    );
    setFilteredData(filteredData);
    setTotalPages(Math.ceil(filteredData.length / perPage));
    setCurrentPage(1);
  };
  //date range
  useEffect(() => {
    let filteredData = societyData;

    if (startDate) {
      filteredData = filteredData.filter((item) => {
        const [day, month, year] = item.submitedDate.split("-");
        const formattedDate = new Date(`${year}-${month}-${day}`);
        return formattedDate >= new Date(startDate);
      });
    }

    if (endDate) {
      filteredData = filteredData.filter((item) => {
        const [day, month, year] = item.submitedDate.split("-");
        const formattedDate = new Date(`${year}-${month}-${day}`);
        return formattedDate <= new Date(endDate);
      });
    }

    if (searchQuery) {
      filteredData = filteredData.filter((item) => {
        return item?.name
          .toLowerCase()
          .trim()
          .includes(searchQuery.trim().toLowerCase());
      });
    }
    setFilteredData(filteredData);
    setTotalPages(Math.ceil(filteredData.length / perPage));
    setCurrentPage(1);
  }, [societyData, searchQuery, startDate, endDate]);
  //Edit Society Functionlaity
  const handleSocietyEditFunctionality = (id) => {
    navigate(`/admin/edit-society/${id}`);
  };
  //Export Data from Excel
  const exportToExcel = (filteredData, filename) => {
    const ws = XLSX.utils.json_to_sheet(
      filteredData.map((item) => ({
        "Society Name": item.name ? item.name : "Not Added",
        "Society Address": item.address ? item.address : "Not Added",
        State: item.state ? item.state : "Not Added",
        City: item.city ? item.city : "Not Added",
        "Society Contact No.": item.societyContactNumber
          ? item.societyContactNumber
          : "Not Added",
        "Society Registration No.": item.societyRegistration
          ? item.societyRegistration
          : "Not Added",
        "Society HouseList": item.societyHouseList
          ? item.societyHouseList
          : "Not Added",
        Status: item.status ? item.status.toUpperCase() : "Not Added",
      }))
    );

    const wscols = [
      { wch: 30 },
      { wch: 40 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
    ];
    ws["!cols"] = wscols;
    const wsrows = [
      { hpt: 20 },
      { hpt: 20 },
      { hpt: 20 },
      { hpt: 20 },
      { hpt: 20 },
      { hpt: 20 },
      { hpt: 20 },
      { hpt: 20 },
      { hpt: 20 },
      { hpt: 20 },
      { hpt: 20 },
      { hpt: 20 },
      { hpt: 20 },
      { hpt: 20 },
      { hpt: 20 },
      { hpt: 20 },
      { hpt: 20 },
    ];
    ws["!rows"] = wsrows;
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const currentTimeAndDate = new Date();
    const dateTime = `${currentTimeAndDate}`;

    FileSaver.saveAs(blob, `${filename} ${dateTime}.xlsx`);
  };
  //Import Society Data
  const exportToImport = () => {
    navigate("/admin/importSocieties");
  };
  // Change Society Status
  const handleStatusChange = async (id, status, item) => {
    try {
      const decode = decodeToken(localStorage.getItem("data"));
      // Set the title based on the status
      const statusTitle = status === "approved" ? "Approve" : "Reject";
      const result = await Swal.fire({
        title: `Are you sure you want to ${statusTitle} this society?`,
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `Yes, ${statusTitle} it!`,
      });

      if (result.isConfirmed) {
        let result2;
        if (statusTitle === "Reject") {
          const options = [
            "Incomplete or incorrect society information (e.g., missing name, address, contact details).",
            "Duplicate entry (already registered).",
            "Non-compliance with GuardX policies or technical requirements.",
            "Fake or fraudulent society documents.",
            "Society fails physical or document verification.",
            "Conflicts with existing agreements or agencies.",
            "Lack of necessary infrastructure to support GuardX services.",
            "Non-payment issues or history of disputes.",
            "Violation of local laws or regulations.",
            "Unresponsive or uncooperative management.",
            "Other", // "Other" option for custom input
          ];

          result2 = await Swal.fire({
            title: "Cause of Rejection",
            html: `
                      <div class="form-group">
                        <label for="rejectionReason" class="form-label">Select a reason:</label>
                        <select id="rejectionReason" class="form-control">
                          ${options
                .map(
                  (option, index) =>
                    `<option value="${index}">${option}</option>`
                )
                .join("")}
                        </select>
                      </div>
                      <div class="form-group mt-3" id="customReasonGroup" style="display: none;">
                        <label for="customReason" class="form-label">Custom Reason:</label>
                        <input id="customReason" class="form-control" type="text" placeholder="Enter your reason" />
                      </div>
                    `,
            showCancelButton: true,
            confirmButtonText: "Save",
            focusConfirm: false,
            preConfirm: () => {
              const reasonSelect = document.getElementById("rejectionReason");
              const customReasonInput = document.getElementById("customReason");
              const selectedValue = reasonSelect.value;

              if (selectedValue === String(options.length - 1)) {
                // "Other" selected
                const customReason = customReasonInput.value.trim();
                if (!customReason) {
                  Swal.showValidationMessage(
                    "You need to provide a cause of rejection!"
                  );
                  return null;
                }
                return customReason; // Return custom input value
              } else {
                return options[selectedValue]; // Return selected dropdown value
              }
            },
            didOpen: () => {
              const reasonSelect = document.getElementById("rejectionReason");
              const customReasonGroup =
                document.getElementById("customReasonGroup");

              // Show/hide custom input based on dropdown selection
              reasonSelect.addEventListener("change", () => {
                if (reasonSelect.value === String(options.length - 1)) {
                  // "Other" selected
                  customReasonGroup.style.display = "block";
                } else {
                  customReasonGroup.style.display = "none";
                  document.getElementById("customReason").value = ""; // Clear custom input
                }
              });
            },
          });

          if (result2.isConfirmed) {
            setRejectionMessage(result2.value); // Set the selected or custom rejection message
          }
        }
        // console.log("sdsdsd", result2);

        if (result2 && !result2.isConfirmed) {
          return;
        }
        const response = await axios.post(`${PORT}updateSocietyStatus/${id}`, {
          status,
          rejectionReason: result2?.value || rejectionMessage || "Na", //19/12/2024
          approvedBy: decode?.data?._id,
        });
        if (response.data) {
          getSocietyData();
          Swal.fire({
            title: "Success!",
            text: `The society has been ${statusTitle}d.`,
            icon: "success",
            timer: 1500,
            timerProgressBar: true,
          });
          await addBilling(item); //20/12/2024
          await welcomeMailToSociety(item); //21/01/2025

          const payload = {
            society_id: id,
            status: status,
            rejectionReason: result2?.value || rejectionMessage || "Na",
            approvedBy: decode?.data?._id,
          };
          // console.log(payload);
          const notify = await axios.post(
            `${PORT}notifyAgencySocietyStatus`,
            payload
          );
        }
      }
    } catch (error) {
      console.log("Error updating society status:", error);
    }
  };
  const addBilling = async (data) => {
    //20/12/2024
    const id = data?._id;
    try {
      if (!data.societyHouseList || typeof data.societyHouseList !== "string") {
        throw new Error(
          "Invalid societyHouseList. Expected a string in the format 'min-max'"
        );
      }
      const dataArray = {
        society_id: id,
        intervalType: data.intervalType,
        discountType: data.discountType,
        societyHouseList: data.societyHouseList,
        planType: data.planType,
        currencyType: data.currencyType || "INR",
        totalHouse: "",
        discountAmount: data.discountAmount,
        amount: "",
        pricePerHouse: data.pricePerHouse,
        affiliateId: data.affiliateId || null,
        submitedDate: getCurrentDate(),
        submitedTime: getCurrentTime(),
        billId: Array(10)
          .fill(null)
          .map(() =>
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789".charAt(
              Math.floor(Math.random() * 62)
            )
          )
          .join(""),
        //affiliate Commision
      };
      if (data.affiliateId) {
        //20/12/2024
        dataArray.affiliatePendingEarnings = 0;
        dataArray.affiliatePaidEarnings = 0;

        const responseCommsion = await axios.post(
          `${PORT}/createCommision`,
          dataArray
        );
      }
      const response = await axios.post(`${PORT}createBill`, dataArray);
    } catch (error) {
      console.error("Error in addBilling:", error.message);
    }
  };
  const welcomeMailToSociety = async (data) => {
    const data1 = {
      societyEmail: data.societyEmail,
      name: data.name,
      city: data.city,
    };
    try {
      const response = await axios.post(
        `${PORT}notifySocietyWelcomeMailApi`,
        data1
      );
    } catch (error) { }
  };
  //handle CLick Outside  accordian close
  const handleClickOutside = (event) => {
    if (accordionRef.current && !accordionRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };
  // Validation logic to ensure endDate is not before startDate
  const handleEndDateChange = (e) => {
    const selectedEndDate = e.target.value;
    // Check if the end date is empty
    if (!selectedEndDate) {
      setEndDate(""); // Clear the end date if it's empty
      return;
    }
    if (selectedEndDate < startDate) {
      // setError("End date can't be before the start date.");
      alert("End date can't be before the start date.");
      setEndDate("");
      return; // Don't update endDate if it's invalid
    }
    setEndDate(selectedEndDate);
  };
  // Validation logic to ensure endDate is not before startDate
  const handleStartDateChange = (e) => {
    const selectedStartDate = e.target.value;

    if (!selectedStartDate) {
      setStartDate("");
      return;
    }
    if (endDate && new Date(selectedStartDate) > new Date(endDate)) {
      alert("Start date cannot be greater than the end date.");
      setStartDate("");
      setEndDate("");
      return;
    }

    setStartDate(selectedStartDate);
  };
  //function lock
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    getSocietyData();
  }, []);
  return (
    <>
      <Layout>
        <div class="container-fluid py-4 ">
          <div class="row">
            <div class="col-12 ">
              <div class="card mb-4">
                <div class="card-header pb-0">
                  <div className="filtered-div">
                    <div
                      ref={accordionRef}
                      className="p-export-btn-society export-dropdown"
                    >
                      <Accordion expanded={isOpen}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                          onClick={handleToggle}
                        >
                          <TiExport className="export_icon" />
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="export-btns">
                            <button
                              className="export_society_btn"
                              onClick={() =>
                                exportToExcel(filteredData, "Society Data")
                              }
                            >
                              Excel
                            </button>
                            <button
                              className="export_society_btn "
                              onClick={exportToImport}
                            >
                              Import
                            </button>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    <div>
                      {permissions[6]?.actions.create ? (
                        <div className="society_add_btn">
                          <button className="" onClick={handleSociety}>
                            {language === "hindi" ? "  Add +" : "+ जोड़ें"}
                          </button>
                        </div>
                      ) : (
                        <p></p>
                      )}
                    </div>

                    <div className="date-filter">
                      <TextField
                        id="start-date"
                        label={
                          language === "hindi"
                            ? "Start Date"
                            : "आरंभ करने की तिथि"
                        }
                        type="date"
                        value={startDate}
                        onChange={handleStartDateChange}
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "#5e72e4" },
                        }}
                        InputProps={{
                          style: { color: "#5e72e4" },
                        }}
                        variant="outlined"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: " #5e72e4",
                              borderWidth: "1px",
                              borderRadius: "5px",
                            },
                            "&:hover fieldset": {
                              borderColor: " #5e72e4",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#5e72e4",
                            },
                            "& .MuiInputLabel-root.Mui-error": {
                              color: "#5e72e4",
                            },
                          },
                        }}
                      />
                      <TextField
                        id="end-date"
                        label={language === "hindi" ? "End Date" : "अंतिम तिथि"}
                        type="date"
                        value={endDate}
                        onChange={handleEndDateChange}
                        InputLabelProps={{
                          shrink: true,
                          style: { color: "#5e72e4" },
                        }}
                        InputProps={{
                          style: { color: "#5e72e4" },
                        }}
                        variant="outlined"
                        disabled={!startDate}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#5e72e4",
                              borderWidth: "1px",
                              borderRadius: "5px",
                            },
                            "&:hover fieldset": {
                              borderColor: " #5e72e4",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: " #5e72e4",
                            },
                            "& .MuiInputLabel-root.Mui-error": {
                              color: "#5e72e4",
                            },
                          },
                        }}
                      />
                    </div>

                    <div className="search-filter-box">
                      <Box
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: " #5e72e4",
                              borderWidth: "1px",
                              borderRadius: "5px",
                            },
                            "&:hover fieldset": {
                              borderColor: " #5e72e4",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: " #5e72e4",
                            },

                            "& .MuiInputLabel-root.Mui-error": {
                              color: "red",
                            },
                          },
                        }}
                      >
                        <TextField
                          id="search-input"
                          label={language === "hindi" ? "Search..." : "खोज..."}
                          variant="outlined"
                          value={searchQuery}
                          onChange={handleSearchInput}
                          className="search-input_society"
                        />
                      </Box>
                    </div>
                  </div>
                </div>
                <div class="card-body px-0 pt-0 pb-2 w-100">
                  <div class="table-responsive p-0">
                    {loadingPermission ? (
                      <div className="three_circle_loader">
                        <ThreeCircles
                          visible={true}
                          height={100}
                          width={100}
                          color="#5e72e4"
                          ariaLabel="three-circles-loading"
                        />
                      </div>
                    ) : permissions[6]?.actions.read ? (
                      <table class="table align-items-center mb-0">
                        <thead>
                          <tr>
                            <th class="text-dark  text-center  text-sm font-weight-bolder opacity-7">
                              {language === "hindi"
                                ? " Society Name"
                                : " सोसायटी का नाम  "}
                            </th>
                            <th class="text-dark   text-center  text-sm font-weight-bolder opacity-7">
                              {language === "hindi" ? " Address" : "पता"}
                            </th>
                            <th class="text-dark   text-center  text-sm font-weight-bolder opacity-7">
                              {language === "hindi" ? "Status" : "स्थिति"}
                            </th>
                            <th class="text-dark   text-center  text-sm font-weight-bolder opacity-7">
                              {language === "hindi" ? "    Date" : "तारीख"}
                            </th>
                            <th class="text-dark   text-center  text-sm font-weight-bolder opacity-7">
                              {language === "hindi" ? "Action" : "कार्रवाई"}
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {filteredData.length > 0 ? (
                            filteredData
                              .slice(
                                (currentPage - 1) * perPage,
                                currentPage * perPage
                              )
                              .map((item, index) => (
                                <tr key={index}>
                                  <td className="align-middle text-center">
                                    {item.name}
                                  </td>
                                  <td className="align-middle text-center">
                                    {item.address}
                                  </td>

                                  <td className=" align-middle text-center">
                                    <span
                                      className={`status-badge ${item.status.toLowerCase()}`}
                                    >
                                      {item.status.toUpperCase()}
                                    </span>
                                  </td>
                                  <td className="align-middle text-center">
                                    {formatDate(item.submitedDate)}
                                  </td>

                                  <td className="align-middle text-center d-flex justify-content-center ">
                                    {item.status == "pending" ? (
                                      <>
                                        {" "}
                                        <Tooltip
                                          title={
                                            language === "hindi"
                                              ? "Rejected"
                                              : "अस्वीकृत"
                                          }
                                          placement="top"
                                          arrow
                                        >
                                          <button
                                            className="edit-btn"
                                            onClick={() => {
                                              handleStatusChange(
                                                item._id,
                                                "rejected"
                                              );
                                            }}
                                            disabled={
                                              item.status !== "approved" &&
                                              item.status !== "pending"
                                            }
                                          >
                                            <FaTimesCircle
                                              className="eyes_view "
                                              disabled={
                                                item.status !== "approved"
                                              }
                                            />
                                          </button>
                                        </Tooltip>
                                        <Tooltip
                                          title={
                                            language === "hindi"
                                              ? "Approved"
                                              : "स्वीकृत"
                                          }
                                          placement="top"
                                          arrow
                                        >
                                          <button
                                            className="edit-btn"
                                            onClick={() =>
                                              handleStatusChange(
                                                item._id,
                                                "approved",
                                                item
                                              )
                                            }
                                            disabled={
                                              item.status !== "rejected" &&
                                              item.status !== "pending"
                                            }
                                          >
                                            <FaCheckCircle className="eyes_view" />
                                          </button>
                                        </Tooltip>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    <GetSocietyDetails item={item} />
                                    {permissions[6]?.actions.edit ? (
                                      <Tooltip
                                        title={
                                          language === "hindi"
                                            ? "Click to Edit"
                                            : "संपादित करें"
                                        }
                                        placement="top"
                                        arrow
                                      >
                                        <button
                                          className="edit-btn"
                                          onClick={() =>
                                            handleSocietyEditFunctionality(
                                              item._id
                                            )
                                          }
                                        >
                                          {" "}
                                          <MdOutlineModeEdit
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title={
                                              language === "hindi"
                                                ? "Click to Edit"
                                                : "संपादित करें"
                                            }
                                          />{" "}
                                        </button>
                                      </Tooltip>
                                    ) : (
                                      <p></p>
                                    )}
                                    {permissions[6]?.actions.delete ? (
                                      <Tooltip
                                        title={
                                          language === "hindi"
                                            ? "Click to Delete"
                                            : "हटाएं"
                                        }
                                        placement="top"
                                        arrow
                                      >
                                        <button
                                          className="dlt-btn "
                                          onClick={() => {
                                            handleDelete(item._id);
                                          }}
                                        >
                                          <MdDelete
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title={
                                              language === "hindi"
                                                ? "Delete"
                                                : "हटाएं"
                                            }
                                          />
                                        </button>
                                      </Tooltip>
                                    ) : (
                                      <></>
                                    )}
                                  </td>
                                </tr>
                              ))
                          ) : (
                            <tr>
                              <td colSpan={6}>
                                <div className="no_data_entry">No data</div>
                              </td>
                            </tr>
                          )}

                          <td colSpan={6}>
                            {totalPages > 1 && (
                              <div className="table-pagination">
                                <Stack spacing={2}>
                                  <Pagination
                                    count={totalPages}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                  />
                                </Stack>
                              </div>
                            )}
                          </td>
                        </tbody>
                      </table>
                    ) : (
                      <div className="permission">
                        <h2>You do not have permission to read this data</h2>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </Layout>
    </>
  );
};

export default SocietyDetails;
