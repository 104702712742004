import React, { useContext } from "react";
import { LanguageContext } from "../../../lib/LanguageContext";
import { TbListDetails } from "react-icons/tb";
import { MdOutlineSubscriptions } from "react-icons/md";

import { MdFlightTakeoff } from "react-icons/md";
import { AiOutlineClockCircle } from "react-icons/ai";
import { FiTag } from "react-icons/fi";
import { FaMoneyBillWave } from "react-icons/fa";
const PlaneDetails = (props) => {
  const data = props.data;
  const { language } = useContext(LanguageContext);
  return (
    <div>
      <div className="society-view-details-main-div">
        <div className="society-view-details-top-heading">
          <div className="top-society-images-heading-icon-div">
            <MdOutlineSubscriptions />
            &nbsp;
            {language === "hindi"
              ? " Society Plan  Details"
              : " सोसायटी योजना विवरण"}
          </div>
        </div>
        <div className="main_content_div">
          <div className="content2_left_div">
            <h3 className="house_title_left">
              <MdFlightTakeoff /> &nbsp;
              {language === "hindi" ? "Plan Name" : "योजना का नाम"}
            </h3>
            <h3 className="house_title_left">
              <AiOutlineClockCircle /> &nbsp;
              {language === "hindi" ? "Interval" : "अंतराल"}
            </h3>
            <h3 className="house_title_left">
              <FiTag /> &nbsp;
              {language === "hindi" ? "Discount Type" : "छूट का प्रकार"}
            </h3>
            <h3 className="house_title_left">
              <FaMoneyBillWave /> &nbsp;
              {language === "hindi" ? "Discount Amount" : "छूट राशि"}
            </h3>
            <h3 className="house_title_left">
              <FaMoneyBillWave /> &nbsp;
              {language === "hindi"
                ? "Price Per household"
                : "प्रत्येक घर की कीमत"}
            </h3>
            <h3 className="house_title_left">
              <FaMoneyBillWave /> &nbsp;
              {language === "hindi" ? "Currency Type" : "मुद्रा प्रकार"}
            </h3>
          </div>
          <div className="content2_right_div">
            <React.Fragment>
              <h3 className="house_title_left">
                {" "}
                <span
                >
                  : {data.planType.toUpperCase()?data.planType.toUpperCase():"--"}
                </span>
              </h3>
              <h3 className="house_title_left">
                <span
                 
                >
                : {data.intervalType.toUpperCase()?data.intervalType.toUpperCase():"--"}
                </span>
              </h3>
              <h3 className="house_title_left">: {data.discountType?data.discountType:"--"}</h3>
              <h3 className="house_title_left">: {data.discountAmount || 0}</h3>
              <h3 className="house_title_left">: {data.pricePerHouse || "NA"}</h3>
              <h3 className="house_title_left">: {data.currencyType || "NA"}</h3>
            </React.Fragment>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlaneDetails;
